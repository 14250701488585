@import '~antd/dist/antd.css';
.navBar {
  display: flex;
  justify-content: center;
  padding: 2%;
  font-size: medium;
  position: fixed;
  width: 100%;
  margin-left: 0;
  opacity: 0.8;
  z-index: 400;
}

@media (max-width: 500px) {
  .navbar {
    font-size: 0;
    padding: 0;
    position: absolute;
    bottom: 0;
    padding-top: 0;
  }
}
